/* global document */
import naja from 'naja';
import netteForms from 'nette-forms';
import 'bootstrap';

import BootstrapModalExtension from './BootstrapModalExtension';
import BootstrapToastExtension from './BootstrapToastExtension';
import LazyLoadExtension from './LazyLoadExtension';
import reportWebVitals from './reportWebVitals';
import './autosubmitDebounce';
import './checkAll';
import './datepicker';
import './filter';
import './formChange';
import './forms';
import './tabs';
import './tooltips';
import './wysiwyg';

import './scss/index.scss';

naja.formsHandler.netteForms = netteForms;
naja.registerExtension(new BootstrapModalExtension());
naja.registerExtension(new BootstrapToastExtension());
naja.registerExtension(new LazyLoadExtension());

document.addEventListener('DOMContentLoaded', naja.initialize.bind(naja));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
