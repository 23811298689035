/* global document */

export default class LazyLoadExtension {
	initialize(naja) {
		this.naja = naja;

		const elements = document.querySelectorAll('[data-lazy-load]');
		Array.prototype.forEach.call(elements, (element) => {
			this.lazyLoad(element);
		});

		naja.snippetHandler.addEventListener('afterUpdate', (event) => {
			const snippetElements = event.detail.snippet.querySelectorAll('[data-lazy-load]');
			Array.prototype.forEach.call(snippetElements, (element) => {
				this.lazyLoad(element);
			});
		});
	}

	lazyLoad(element) {
		const url = element.dataset.lazyLoad;
		if (url) {
			this.naja.makeRequest('GET', url, null, {
				abort: false,
				unique: url,
			});
		}
	}
}
